.cguy-panel {
  color: #FFEE00;

  .token-released {
    font: {
      family: 'Open Sans', sans-serif;
      size: 120px;
      weight: bold;
    };
  }

  h2 {
    font: {
      family: 'Open Sans', sans-serif;
      size: 50px;
      weight: bold;
    };
    text-transform: uppercase;
    overflow: hidden;
    width: 100%;

    span {
      display: inline-block;
      position: relative;

      &::after {
        background-color: #FFEE00;
        content: "";
        height: 1px;
        position: absolute;
        right: -10px;
        top: 70%;
        transform: translateY(-50%) translateX(100%);
        width: 30vw;
      }
    }
  }

  p {
    font: {
      family: 'Courier New', monospace;
      size: 12px;
      weight: bold;
    };
    text-transform: uppercase;
  }

  .release-button {
    border: 2px solid #FFEE00;
    border-radius: 50px;
    color: #FFEE00;
    display: block;
    font-weight: bold;
    margin-top: 20px;
    outline: none;
    padding: 10px 30px;

    &.disabled {
      cursor: default;
      opacity: .3;
    }
  }
}