@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body, html {
  color: white;
  font: {
    family: 'Courier New', monospace;
    size: 16px;
    weight: normal;
  }
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;


}

.App {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2a284c+0,08080f+100 */
  background: #2a284c; /* Old browsers */
  background: -moz-linear-gradient(top,  #2a284c 0%, #08080f 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #2a284c 0%,#08080f 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #2a284c 0%,#08080f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2a284c', endColorstr='#08080f',GradientType=0 ); /* IE6-9 */


  align-items: center;
  display: flex;
  justify-content: center;

  height: 100vh;
}

.error-message {
  color: #AC641C;
}