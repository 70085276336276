.contract-details {
  p {
    font: {
      family: 'Courier New', monospace;
      size: 16px;
      weight: bold;
    };
    line-height: 8px;
    margin-top: 10px;
  }
}