.login-button {
  background: {
    image: url("../assets/polygon-logo.svg");
    position: 20px center;
    repeat: no-repeat;
  }
  border: 2px solid #FFEE00;
  border-radius: 50px;
  color: #FFEE00;
  display: block;
  font-weight: bold;
  margin-top: 90px;
  outline: none;
  padding: 10px 30px 10px 60px;

}