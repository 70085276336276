.tokens-panel {
  h1 {
    font: {
      family: 'Open Sans', sans-serif;
      size: 50px;
      weight: normal;
    };
  }

  h2 {
    font: {
      family: 'Courier New', monospace;
      size: 12px;
      weight: bold;
    };
    text-transform: uppercase;
    overflow: hidden;
    width: 100%;

    span {
      display: inline-block;
      position: relative;

      &::after {
        background-color: #fff;
        content: "";
        height: 1px;
        position: absolute;
        right: -10px;
        top: 70%;
        transform: translateY(-50%) translateX(100%);
        width: 30vw;
      }
    }
  }

  .price {
    color: #FCEE4F;
    font: {
      family: 'Courier New', monospace;
      size: 16px;
      weight: bold;
    };
    margin: 0!important;
  }
}