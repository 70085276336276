.footer {
  background-color: #FFEE00;
  bottom: 0;
  color: #000000;
  height: 150px;
  position: fixed;
  width: 100%;

  h3 {
    font: {
      family: 'Open Sans', sans-serif;
      size: 50px;
      weight: bold;
    };
  }
}